export default {
  processing_status: (processedAt) => {
    const statusIndicators = {
      processed: "<span class='badge color-green-white'>Processado</span>",
      not_processed: "<span class='badge color-red-white'>Não processado</span>",
    };
    return processedAt
      ? statusIndicators.processed
      : statusIndicators.not_processed;
  },
  last_status: (history) => {
    let lastStatus = '';
    if (history) {
      const historyOnArray = JSON.parse(history);
      lastStatus = historyOnArray[0].text;
    }
    return lastStatus;
  },
};
