<template>
  <div>
    <div class="form-row">
      <div class="col-3">
        <stat-card
          minheight="70px"
          title="Recebido em"
          :value="this.$options.filters.datetime_brl(this.received_post.created_at)"
        />
      </div>
      <div class="col-3">
        <stat-card
          minheight="70px"
          title="Plataforma"
          :value="$options.filters.platform_logo(received_post.platform)"
        />
      </div>
      <div class="col-6">
        <stat-card
          minheight="70px"
          title="Problema"
          :value="$options.filters.last_status(this.received_post.history)"
        />
      </div>
    </div>
    <div class="payload_container">
      <title-box>Dados</title-box>
      <pre>{{ JSON.parse(received_post.payload) }}</pre>
    </div>
    <div class="history_container">
      <title-box>Histórico</title-box>
      <ul>
        <li v-for="(history, ind_history) in history_list" :key="ind_history" i>
          {{ history.created_at | datetime_brl }} - {{ history.text }}
        </li>
      </ul>
    </div>
    <cancel-button cancel_text="Fechar" @cancel="close" />
  </div>
</template>
<script type="text/javascript">
import CancelButton from '@/theme/buttons/CancelButton.vue';
import StatCard from '@/theme/boxes/StatCard.vue';
import { mapGetters } from 'vuex';
import TitleBox from '@/theme/boxes/TitleBoxInsideContainers.vue';
import filters from './filters';

export default {
  props: {
    post_id: {
      type: [String, Number],
    },
  },
  filters,
  components: {
    CancelButton,
    StatCard,
    TitleBox,
  },
  data() {
    return {
      received_post: {},
    };
  },
  methods: {
    ...mapGetters('receivedPosts', ['GetterReceivedPost']),
    close() {
      this.$emit('close');
    },
  },
  computed: {
    history_list() {
      if (!this.received_post.history) return [];
      return JSON.parse(this.received_post.history);
    },
  },
  mounted() {
    this.received_post = this.GetterReceivedPost()({ id: this.post_id });
    this.received_post.payload = typeof this.received_post.payload === 'object'
      ? JSON.parse(this.received_post.payload)
      : this.received_post.payload;
  },
};
</script>
<style type="text/css" scoped>
.payload_container {
  max-height: 250px;
  overflow-y: scroll;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}
.history_container {
  max-height: 150px;
  overflow-y: scroll;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
