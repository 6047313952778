<template>
  <div>
    <spinner :loading="processing" />
    <content-box icon="fa-inbox" title="Entradas não processadas">
      <div class="row mb-20">
        <div class="offset-sm-9 col-sm-3 col">
          <platforms v-model="selected_platform" />
        </div>
      </div>
      <div class="show_on_pc">
        <div class="table_container">
          <table class="table table-striped center">
            <thead>
              <th>Recebimento</th>
              <th>Origem</th>
              <th>Problema</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="post in receivedPosts" :key="post.id">
                <td v-html="$options.filters.datetime_brl(post.created_at)"></td>
                <td v-html="$options.filters.platform_logo(post.platform)"></td>
                <td v-html="$options.filters.last_status(post.history)"></td>
                <td>
                  <data-action
                    :actions="data_actions"
                    @details="details(post.id)"
                    @delete_post="delete_post(post.id)"
                    @reprocess="reprocess(post.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <paginator :current="filter.page" :pages="filter.pages" @paginate="paginate" />
    </content-box>
    <modal size="lg" title="Dados recebidos" v-if="show_modal.details">
      <received-posts-details :post_id="details_post_id" @close="show_modal.details = false" />
    </modal>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions } from 'vuex';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import DataAction from '@/theme/components/DataActions.vue';
import Paginator from '@/features/paginator/Paginator.vue';
import Modal from '@/theme/boxes/Modal.vue';
import Spinner from '@/features/Spinner/Spinner.vue';

import showHttpErrors from '@/mixins/ShowHttpErrors/showhttperrors';
import ReceivedPostsDetails from './ReceivedPostsDetails.vue';
import Platforms from './ReceivedPostsPlatforms.vue';

import filters from './filters';

export default {
  components: {
    ContentBox,
    DataAction,
    Paginator,
    Modal,
    ReceivedPostsDetails,
    Spinner,
    Platforms,
  },
  filters,
  mixins: [showHttpErrors],
  data() {
    return {
      processing: false,
      filter_box_opened: false,
      show_modal: {
        details: false,
      },
      selected_platform: '',
      details_post_id: 0,
      data_actions: [
        {
          icon: 'fa-search',
          text: 'Ver detalhes',
          event: 'details',
          color: 'blue',
        },
        {
          icon: 'fa-redo',
          text: 'Processar novamente',
          event: 'reprocess',
          color: 'green',
        },
        {
          icon: 'fa-trash',
          text: 'Apagar',
          event: 'delete_post',
          color: 'red',
        },
      ],
    };
  },
  methods: {
    ...mapActions('receivedPosts', [
      'ActionListReceivedPosts',
      'ActionListReceivedPostsPaginate',
      'ActionReprocessReceivedPost',
      'ActionDeleteReceivedPost',
      'ActionReceivedPostsFilterPlatform',
    ]),
    async paginate(page) {
      this.processing = true;
      await this.ActionListReceivedPostsPaginate({ page });
      this.processing = false;
    },
    details(id) {
      this.details_post_id = id;
      this.show_modal.details = true;
    },
    delete_post(id) {
      this.$dialogbox
        .confirm('Confirma a exclusão desta entrada?')
        .then(async () => {
          try {
            this.processing = true;
            await this.ActionDeleteReceivedPost({ id });
            await this.ActionListReceivedPosts();
          } catch (error) {
            console.log(error);
            this.showHttpErrors_on_dialogbox(error);
          } finally {
            this.processing = false;
          }
        });
    },
    async reprocess(id) {
      this.processing = true;
      try {
        await this.ActionReprocessReceivedPost({ id });
      } catch (error) {
        const { errors } = error.response.data;
        if (errors) {
          const keys = Object.keys(errors);
          this.$dialogbox.error(errors[keys[0]][0]);
        }
        console.log(error);
      } finally {
        await this.getPosts();
        this.processing = false;
      }
    },
    async getPosts() {
      await this.ActionListReceivedPosts();
    },
  },
  computed: {
    ...mapState('receivedPosts', ['receivedPosts', 'filter']),
  },
  watch: {
    selected_platform: {
      async handler() {
        this.processing = true;
        await this.ActionReceivedPostsFilterPlatform({
          platform: this.selected_platform,
        });
        this.processing = false;
      },
    },
  },
  async mounted() {
    this.processing = true;
    await this.getPosts();
    this.processing = false;
  },
};
</script>
<style type="text/css"></style>
