<template>
  <general-selector v-model="selected_item" :items="mapped_platforms" />
</template>
<script type="text/javascript">
import { mapActions, mapState } from 'vuex';
import GeneralSelector from '@/components/Selectors/GeneralSelector.vue';

export default {
  components: {
    GeneralSelector,
  },
  props: ['value'],
  data() {
    return {
      selected_item: '',
    };
  },
  methods: {
    ...mapActions('receivedPosts', ['ActionListReceivedPostsPlatforms']),
  },
  computed: {
    ...mapState('receivedPosts', ['platforms']),
    mapped_platforms() {
      const platforms = this.platforms.map((platform) => ({
        key: platform.platform,
        value: platform.platform,
      }));
      platforms.unshift({ key: '', value: 'Todas as plataformas' });
      return platforms;
    },
  },
  watch: {
    value: {
      handler() {
        this.selected_item = this.value;
      },
    },
    selected_item: {
      handler() {
        this.$emit('input', this.selected_item);
      },
    },
  },
  async mounted() {
    await this.ActionListReceivedPostsPlatforms();
    this.selected_item = this.value;
  },
};
</script>
